import React, { useContext } from 'react';
import './styles.scss';
import { ResetOverlayProps } from './interfaces';
import { ScreenEnum } from '../../enums';
import { useTranslation } from 'react-i18next';
import AppStateContext from '../../AppStateProvider/context';

export default function ResetOverlay(props: ResetOverlayProps) {
	const { dispatch } = useContext(AppStateContext);
	const { t } = useTranslation();

	return (
		<div className='reset-overlay'>
			<div className='inner'>
				<h3>{t('ResetOverlayHeadline')}</h3>
				<p className='p2'>{t('ResetOverlayText')}</p>
				<div>
					<button
						className='black small'
						onClick={() => props.setResetOverlay(false)}
					>
						{t('ButtonCancel')}
					</button>
					<button
						className='red small'
						onClick={() => {
							props.setResetOverlay(false);
							localStorage.removeItem('setup');
							dispatch({ type: 'SET_SETUP', value: null });
							props.setScreen(ScreenEnum.welcome);
						}}
					>
						{t('ResetOverlayReset')}
					</button>
				</div>
			</div>
		</div>
	);
}

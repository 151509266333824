import React from 'react';
import './styles.scss';
import { OptionProps } from './interfaces';

export default function Option(props: OptionProps) {
	return (
		<div
			className={`option${props.selected ? ' selected' : ''}${
				props.icon ? ' with-icon' : ''
			}${props.checkMark ? ' with-checkmark' : ''}${
				props.className ? ' ' + props.className : ''
			}${props.disabled ? ' disabled' : ''}`}
			onClick={() => props.onClick()}
		>
			<div>
				{props.checkMark && props.selected && (
					<div className='checkmark' />
				)}
			</div>
			<p className='p1'>{props.text}</p>
			{props.icon}
		</div>
	);
}

import React, { useContext } from 'react';
import './styles.scss';
import { ScreenEnum } from '../../enums';
import { CheckmarkBig } from '../../assets/Icons';
import AppStateContext from '../../AppStateProvider/context';

export default function Progress(props: {
	screen: ScreenEnum;
	setScreen: (next: ScreenEnum) => void;
}) {
	const { state } = useContext(AppStateContext);
	const screenArray = state.apk
		? [
				ScreenEnum.hardwareCheck,
				ScreenEnum.setSecond,
				ScreenEnum.softwareCheck,
				ScreenEnum.selectBrand,
				ScreenEnum.selectMarket,
				ScreenEnum.validation,
				ScreenEnum.selectDevice,
		  ]
		: [
				ScreenEnum.hardwareCheck,
				ScreenEnum.selectBrand,
				ScreenEnum.selectMarket,
				ScreenEnum.validation,
				ScreenEnum.selectDevice,
		  ];
	const screenIndex = screenArray.findIndex(key => key === props.screen);

	if (screenIndex === -1) {
		return <></>;
	}

	return (
		<div className='progress'>
			{screenArray.map((key, index) => (
				<div
					className={`dot${index > screenIndex ? ' grey' : ''}${
						screenIndex > index && key !== ScreenEnum.validation
							? ' hover'
							: ''
					}`}
					key={`dot-${index}`}
					onClick={() => {
						if (
							screenIndex > index &&
							key !== ScreenEnum.validation
						) {
							props.setScreen(screenArray[index]);
						}
					}}
				>
					<>
						{index < screenIndex && <div>{CheckmarkBig}</div>}
						{index === screenIndex && <div className='red' />}
						{index > screenIndex && (
							<p className='p2'>{index + 1}</p>
						)}
					</>
				</div>
			))}
		</div>
	);
}

export const EyeShow = (
	<svg id='Ebene_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
		<path
			d='M14,12c0,1.1-.9,2-2,2s-2-.9-2-2,.9-2,2-2,2,.9,2,2Zm10,0s-5.37,8-12,8S0,12,0,12c0,0,5.37-8,12-8s12,8,12,8Zm-6,0c0-3.31-2.69-6-6-6s-6,2.69-6,6,2.69,6,6,6,6-2.69,6-6Z'
			fill='currentColor'
		/>
	</svg>
);
export const EyeHide = (
	<svg id='Ebene_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
		<path
			d='M2.71,1L22.51,20.8l-1.41,1.41L1.29,2.42l1.41-1.41ZM11.31,6.78c2.65-.46,5.16,1.32,5.62,3.96,.06,.32,.08,.64,.07,.97,0,.23-.03,.46-.07,.69l3.43,3.42c1.36-1.24,2.58-2.61,3.64-4.11,0,0-5.37-8-12-8-1.08,.01-2.15,.22-3.16,.6l2.47,2.47Zm1.38,9.86c-2.65,.46-5.16-1.32-5.62-3.96-.09-.55-.09-1.11,0-1.65l-3.42-3.42C2.28,8.83,1.06,10.21,0,11.71,0,11.71,5.37,19.71,11.99,19.71c1.08-.01,2.15-.22,3.16-.6l-2.47-2.47Z'
			fill='currentColor'
		/>
	</svg>
);
export const Close = (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
		<polygon points='4.22 18.36 10.59 12 4.22 5.64 5.64 4.22 12 10.58 18.36 4.22 19.78 5.64 13.41 12 19.78 18.36 18.36 19.78 12 13.41 5.64 19.78 4.22 18.36' />
	</svg>
);
export const Back = (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
		<path
			d='M20.49,10.54,13.42,3.47,12,2.05,3.51,10.54l1.42,1.41L11,5.88V22h2V5.88l6.07,6.07Z'
			transform='translate(-0.025 24.025) rotate(-90)'
			fill='#fff'
		/>
	</svg>
);

export const Info = (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
		<path
			d='M11,6h2v7H11Zm0,10h2v2H11ZM12,2A10,10,0,1,1,2,12,10.011,10.011,0,0,1,12,2m0-2A12,12,0,1,0,24,12,12,12,0,0,0,12,0'
			fill='#fff'
		/>
	</svg>
);

export const Enter = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='24'
		height='24'
		viewBox='0 0 24 24'
	>
		<path d='M1,15l7-4v3H19V9H16V7h5v9H8v3Z' fill='currentColor' />
	</svg>
);

export const Off = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='24'
		height='24'
		viewBox='0 0 24 24'
	>
		<path
			d='M23,13A11,11,0,1,1,6.5,3.48V7.2a8,8,0,1,0,11,0V3.48A11,11,0,0,1,23,13Zm-9.5-1V0h-3V12Z'
			fill='currentColor'
		/>
	</svg>
);

export const Setup = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='24'
		height='24'
		viewBox='0 0 24 24'
	>
		<path
			d='M23,4H19.82a2.988,2.988,0,0,0-5.64,0H1V6H14.18a2.988,2.988,0,0,0,5.64,0H23ZM17,6a1,1,0,1,1,1-1A1,1,0,0,1,17,6Zm6,5H11.82a2.988,2.988,0,0,0-5.64,0H1v2H6.18a2.988,2.988,0,0,0,5.64,0H23ZM9,13a1,1,0,1,1,1-1A1,1,0,0,1,9,13Zm14,5H19.82a2.988,2.988,0,0,0-5.64,0H1v2H14.18a2.988,2.988,0,0,0,5.64,0H23Zm-6,2a1,1,0,1,1,1-1A1,1,0,0,1,17,20Z'
			fill='currentColor'
		/>
	</svg>
);

export const CheckmarkBig = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='24rem'
		height='24rem'
		viewBox='0 0 24 24'
	>
		<polygon
			points='19.19 5.56 10 14.76 5.05 9.81 2.93 11.93 10 19 21.31 7.69 19.19 5.56'
			fill='#ffffff'
		/>
	</svg>
);

export const CheckmarkPassword = (
	<svg
		id='ic16-checkmark-small'
		xmlns='http://www.w3.org/2000/svg'
		width='16'
		height='16'
		viewBox='0 0 16 16'
	>
		<rect id='BB' width='16' height='16' fill='none' />
		<path
			id='Path_784'
			data-name='Path 784'
			d='M12.54,3.46,6.29,9.71,3.46,6.88,2.05,8.29l4.24,4.25,7.66-7.66Z'
		/>
	</svg>
);

export const CrossPassword = (
	<svg
		id='ic16-close'
		xmlns='http://www.w3.org/2000/svg'
		width='16'
		height='16'
		viewBox='0 0 16 16'
	>
		<rect id='BB' width='16' height='16' fill='none' />
		<path
			id='Path_742'
			data-name='Path 742'
			d='M6.341,16.239,10.59,12,6.341,7.761l1.42-1.42L12,10.58l4.239-4.239,1.42,1.42L13.41,12l4.249,4.239-1.42,1.42L12,13.41,7.761,17.659Z'
			transform='translate(-4 -4)'
		/>
	</svg>
);

export const Copy = (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
		<rect id='BB' fill='none' />
		<path
			id='Path_786'
			data-name='Path 786'
			d='M20,5H17V2a2.006,2.006,0,0,0-2-2H4A2.006,2.006,0,0,0,2,2V17a2.006,2.006,0,0,0,2,2H7v3a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V7A2.006,2.006,0,0,0,20,5ZM4,17V2H15V5H9A2.006,2.006,0,0,0,7,7V17Z'
			fill='#fff'
		/>
	</svg>
);

export const CheckmarkPasswordToggle = (
	<svg
		id='ic24-checkmark-big-thin'
		xmlns='http://www.w3.org/2000/svg'
		width='24rem'
		height='24rem'
		viewBox='0 0 24 24'
	>
		<g id='Raw'>
			<path
				id='Path_785'
				data-name='Path 785'
				d='M19.42,5.64l-9.89,9.9L4.58,10.59,3.16,12l4.95,4.95,1.42,1.41L20.84,7.05Z'
				fill='#000'
			/>
		</g>
		<g id='Final'>
			<rect id='BB' width='24' height='24' fill='none' />
		</g>
	</svg>
);

export const Placeholder = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1080'
		height='1920'
		viewBox='0 0 1080 1920'
	>
		<defs>
			<clipPath id='clip-Screen_calibration_second_screen_placeholder'>
				<rect width='1080' height='1920' />
			</clipPath>
		</defs>
		<g
			id='Screen_calibration_second_screen_placeholder'
			data-name='Screen calibration – second screen placeholder'
			clip-path='url(#clip-Screen_calibration_second_screen_placeholder)'
		>
			<rect width='1080' height='1920' fill='#1a1a1a' />
			<rect
				id='Rechteck_1962'
				data-name='Rechteck 1962'
				width='1081'
				height='1920'
				fill='#3c3c3c'
			/>
			<rect
				id='Rechteck_1968'
				data-name='Rechteck 1968'
				width='936'
				height='526'
				transform='translate(72 72)'
				fill='#1a1a1a'
			/>
			<rect
				id='Rechteck_1970'
				data-name='Rechteck 1970'
				width='222'
				height='20'
				transform='translate(72 630)'
				fill='#1a1a1a'
			/>
			<rect
				id='Rechteck_1973'
				data-name='Rechteck 1973'
				width='222'
				height='20'
				transform='translate(548 630)'
				fill='#1a1a1a'
			/>
			<rect
				id='Rechteck_1971'
				data-name='Rechteck 1971'
				width='222'
				height='20'
				transform='translate(310 630)'
				fill='#1a1a1a'
			/>
			<rect
				id='Rechteck_1972'
				data-name='Rechteck 1972'
				width='222'
				height='20'
				transform='translate(786 630)'
				fill='#1a1a1a'
			/>
			<g id='ic64-arrow-up' transform='translate(476 896)'>
				<rect
					id='BB'
					width='128'
					height='128'
					transform='translate(128 128) rotate(180)'
					fill='none'
				/>
				<path
					id='Pfad_774'
					data-name='Pfad 774'
					d='M118.63,54.451,74.236,14.265,64,5,53.764,14.265,9.37,54.451l10.26,9.265L56.758,30.108V123H71.242V30.108L108.37,63.716Z'
					transform='translate(0.06 0)'
					fill='#1a1a1a'
				/>
			</g>
			<g
				id='Ellipse_10'
				data-name='Ellipse 10'
				transform='translate(164 152)'
				fill='none'
				stroke='#3c3c3c'
				stroke-width='8'
			>
				<circle cx='64' cy='64' r='64' stroke='none' />
				<circle cx='64' cy='64' r='60' fill='none' />
			</g>
			<path
				id='Pfad_776'
				data-name='Pfad 776'
				d='M-19304.889-14997.835l115.328-104.962,65.807,59.906,118.666,108.026'
				transform='translate(19954.895 15419.646)'
				fill='none'
				stroke='#3c3c3c'
				stroke-linecap='round'
				stroke-width='8'
			/>
			<path
				id='Pfad_777'
				data-name='Pfad 777'
				d='M-19374.078-14879.231l245.645-223.565,87.607,79.752,157.979,143.813'
				transform='translate(19602.27 15364.014)'
				fill='none'
				stroke='#3c3c3c'
				stroke-linecap='round'
				stroke-width='8'
			/>
		</g>
	</svg>
);

import { Close } from '../../assets/Icons';
import React, { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

export default function WarningOverlay(props: {
	headline: string;
	texts: Array<string | undefined>;
	btnFunction: MouseEventHandler<HTMLElement>;
	close?: MouseEventHandler<HTMLElement>;
	okBtn?: boolean;
	btns?: MouseEventHandler<HTMLElement>;
	btnsText?: string;
}) {
	const { t } = useTranslation();

	return (
		<div className='warning-overlay-container' onClick={props.close}>
			<div>
				{!props.okBtn && (
					<button
						className='black unset circular'
						onClick={props.btnFunction}
					>
						{Close}
					</button>
				)}
				<h3>{t(props.headline)}</h3>
				{props.texts.map((text, index) => (
					<React.Fragment key={`${props.headline}-${text}-${index}`}>
						{text && <p className='p2'>{t(text)}</p>}
					</React.Fragment>
				))}
				{props.okBtn && (
					<button className='small red' onClick={props.btnFunction}>
						{t('Ok')}
					</button>
				)}
				{props.close && props.okBtn && (
					<button className='small black' onClick={props.close}>
						{t('Close')}
					</button>
				)}
				{props.btns && (
					<button className='small black' onClick={props.btns}>
						{t(props.btnsText)}
					</button>
				)}
			</div>
		</div>
	);
}

import React from 'react';

export class ErrorBoundary extends React.Component {
	state = {
		hasError: false,
	};

	static getDerivedStateFromError(error: any) {
		return {
			hasError: true,
			error,
		};
	}

	componentDidCatch(error: any, info: any) {
		console.log(error, info);
	}

	render() {
		if (this.state.hasError) {
			if (window.location.search.includes('in-frame=true')) {
				window.parent.postMessage('error', '*');
			}

			return <></>;
		}

		// @ts-ignore
		return this.props.children;
	}
}

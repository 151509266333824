import dataJson from '../data.json';
import { AppState } from '../AppStateProvider/interfaces';

export function findMarketByLanguageCountry(
	languageCountry?: string,
	brand?: string | null
) {
	const brandObj = (dataJson.brands as Array<any>).find(
		(b: { name: any }) => b.name === brand
	);
	if (brandObj) {
		for (let market of brandObj.markets) {
			if (market.ending === languageCountry) {
				return market as AppState['market'];
			}
		}
		return;
	} else {
		return;
	}
}

import { AppState } from '../AppStateProvider/interfaces';

const superPassword = 'h7do9E8!s4pLk@3iS8la'; // todo replace
const superPasswordB12 = 'b12did2024';

export function checkPW(pw: string, state: AppState): boolean {
	if (pw === superPassword || pw === superPasswordB12) return true;
	if (state.userPassword) {
		return pw === state.userPassword;
	}
	return pw === `${state.kvps || state.brand || 'setup'}@did`;
}

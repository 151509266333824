import React, { useContext, useState } from 'react';
import './App.scss';
import { ScreenEnum } from './enums';
import Launch from './screens/Launch';
import Welcome from './screens/Welcome';
import SelectBrand from './screens/SelectBrand';
import SelectMarket from './screens/SelectMarket';
import Validation from './screens/Validation';
import SelectDevice from './screens/Select Device';
import Final from './screens/Final';
import { useTranslation } from 'react-i18next';
import { redirect } from './utils/redirect';
import Label from './components/Label';
import SetSecond from './screens/SetSecond';
import Unlock from './components/Unlock';
import { Back } from './assets/Icons';
import { complete } from './utils/complete';
import AppStateContext from './AppStateProvider/context';
import Keyboard from './components/Keyboard';
import HardwareCheck from './screens/HardwareCheck';
import SoftwareCheck from './screens/SoftwareCheck';
import addParam from './utils/addParam';
import WarningOverlay from './components/WarningOverlay';
import Exit from './screens/Exit';
import { Password } from './screens/Password';

function App() {
	const { state, dispatch } = useContext(AppStateContext);
	const [showCancelInfo, setShowCancelInfo] = useState<boolean>(false);

	// brand, market, kvps, password, device, url, language=de-DE, user-password etc.,
	// label=true, redirect=false, orientation=default|right|down|left, in-frame=true, apk=true, change-device=true
	// version, software-check=true|false, device-type
	const params = new URLSearchParams(window.location.search);

	const redirectBool = params.get('redirect') !== 'false';
	if (redirectBool && state.setup && state.setup.brand && !state.offline) {
		redirect(state.setup.brand);
	}

	window.addEventListener('online', () =>
		dispatch({ type: 'SET_OFFLINE', value: false })
	);
	window.addEventListener('offline', () =>
		dispatch({ type: 'SET_OFFLINE', value: true })
	);

	const { t, i18n } = useTranslation();
	if (i18n.language !== state.language) {
		i18n.changeLanguage(state.language);
	}

	if (params.get('in-frame') === 'true') {
		// setting root font size to scale everything with the window height
		const root = document.querySelector(':root') as HTMLElement | null;
		if (root) {
			root.style.fontSize = `${window.innerHeight / 1920}px`;
		}
		window.parent.postMessage('loaded', '*');
		return <Unlock inFrame={true} />;
	}

	if (params.get('label') === 'true') {
		return <Label />;
	}

	if (state.apk && params.get('locked') === null) {
		addParam('locked', 'true');
	}

	const cancelBtn = (
		<button className='cancel' onClick={() => setShowCancelInfo(true)}>
			{t('ButtonCancel')}
		</button>
	);

	const backBtn = (back: ScreenEnum) => (
		<button
			className='back-btn black unset'
			onClick={() => setScreenWithCheck(back)}
		>
			{Back}
			{t('Back')}
		</button>
	);

	function setScreenWithCheck(next: ScreenEnum) {
		if (
			next === ScreenEnum.welcome &&
			state.language === params.get('language')
		) {
			next = ScreenEnum.hardwareCheck;
		}
		if (next === ScreenEnum.setSecond && !state.apk) {
			next = ScreenEnum.selectBrand;
		}
		if (next === ScreenEnum.selectBrand && params.get('brand')) {
			next = ScreenEnum.selectMarket;
		}
		if (
			next === ScreenEnum.selectMarket &&
			((params.get('url') && params.get('password')) ||
				(params.get('market') &&
					params.get('kvps') &&
					params.get('password'))) &&
			!(
				state.errors.validationError ||
				state.errors.passwordError ||
				state.errors.urlError
			)
		) {
			next = ScreenEnum.validation;
		}
		if (next === ScreenEnum.validation && params.get('device')) {
			next = ScreenEnum.selectDevice;
		}
		if (next === ScreenEnum.selectDevice && params.get('device')) {
			next = ScreenEnum.final;
		}
		if (next === ScreenEnum.final) {
			next = complete(state) ? ScreenEnum.final : ScreenEnum.welcome;
		}
		dispatch({ type: 'SET_SCREEN', value: next });
	}

	const chosenScreen = () => {
		switch (state.screen) {
			case ScreenEnum.launch:
				return (
					<Launch backBtn={backBtn} setScreen={setScreenWithCheck} />
				);
			case ScreenEnum.welcome:
				return (
					<Welcome
						cancelBtn={cancelBtn}
						setScreen={setScreenWithCheck}
						version={params.get('version')}
					/>
				);
			case ScreenEnum.setSecond:
				return (
					<SetSecond
						showSecond={params.get('orientation') !== null}
						backBtn={backBtn}
						setScreen={setScreenWithCheck}
					/>
				);
			case ScreenEnum.hardwareCheck:
				return (
					<HardwareCheck
						setScreen={setScreenWithCheck}
						backBtn={backBtn}
						cancelBtn={cancelBtn}
						deviceType={params.get('device-type')}
					/>
				);
			case ScreenEnum.softwareCheck:
				return (
					<SoftwareCheck
						backBtn={backBtn}
						setScreen={setScreenWithCheck}
						checked={params.get('software-check') === 'true'}
					/>
				);
			case ScreenEnum.selectBrand:
				return (
					<SelectBrand
						setScreen={setScreenWithCheck}
						backBtn={backBtn}
						cancelBtn={cancelBtn}
					/>
				);
			case ScreenEnum.selectMarket:
				return (
					<SelectMarket
						backBtn={backBtn}
						cancelBtn={cancelBtn}
						setScreen={setScreenWithCheck}
					/>
				);
			case ScreenEnum.validation:
				return (
					<div className='top'>
						<Validation
							backBtn={backBtn}
							setScreen={setScreenWithCheck}
						/>
					</div>
				);
			case ScreenEnum.selectDevice:
				return (
					<SelectDevice
						backBtn={backBtn}
						cancelBtn={cancelBtn}
						setScreen={setScreenWithCheck}
					/>
				);
			case ScreenEnum.validationDevice:
				return (
					<div className='top'>
						<Validation
							backBtn={backBtn}
							setScreen={setScreenWithCheck}
							checkDevice={true}
						/>
					</div>
				);
			case ScreenEnum.password:
				return <Password triggerValidation={true} />;
			case ScreenEnum.final:
				return <Final />;
			case ScreenEnum.exit:
				return <Exit setScreen={setScreenWithCheck} />;
			case ScreenEnum.exitNoCancel:
				return <Exit />;
			default:
				return <></>;
		}
	};

	return (
		<div
			className='App'
			onClick={e => {
				if (
					(e.target as HTMLElement).tagName !== 'INPUT' &&
					state.screen !== ScreenEnum.exit
				) {
					dispatchEvent(new CustomEvent('keyboard-blur'));
				}
			}}
		>
			{chosenScreen()}
			{showCancelInfo && (
				<WarningOverlay
					headline={
						state.screen !== ScreenEnum.welcome
							? 'CancelInfoHeadline'
							: 'CancelCloseAppInfoHeadline'
					}
					texts={[
						state.screen !== ScreenEnum.welcome
							? 'CancelInfoText'
							: 'CancelCloseAppInfoText',
					]}
					btnFunction={() => {
						setShowCancelInfo(false);
						dispatch({
							type: 'SET_SCREEN',
							value:
								state.screen !== ScreenEnum.welcome
									? ScreenEnum.welcome
									: ScreenEnum.exit,
						});
					}}
					close={() => {
						setShowCancelInfo(false);
					}}
					okBtn={true}
				/>
			)}
			{state.screen !== ScreenEnum.exit && <Keyboard />}
			{state.offline && <div className='offline-lamp' />}
		</div>
	);
}

export default App;

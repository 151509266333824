import React, { useEffect } from 'react';
import './styles.scss';
import Option from '../Option';
import { RadioButtonsProps } from './interfaces';

export default function RadioButtons(props: RadioButtonsProps) {
	useEffect(() => {
		// make a list of all possible options (not disabled or display none)
		const activeButtons: any = [];
		props.options.forEach(button => {
			if (!button.disabled && !button.displayNone) {
				activeButtons.push(button);
			}
		});

		if (
			activeButtons.length === 1 &&
			!activeButtons[0].selected &&
			!props.disabled
		) {
			// if there is only one possible option, select it
			props.onClick(activeButtons[0].value);
		}
	}, []);

	return (
		<div className={`radio-buttons${props.disabled ? ' disabled' : ''}`}>
			{props.headline && <h4>{props.headline}</h4>}
			{props.warning && <p className='warning'>{props.warning}</p>}
			{props.options.map((option, index) => (
				<Option
					key={`radio-option-${index}-${option.value}`}
					{...option}
					className={option.displayNone ? 'display-none' : undefined}
					onClick={() => {
						if (!props.disabled) props.onClick(option.value);
					}}
				/>
			))}
		</div>
	);
}

import { PRECACHE_NAME } from './registerSW';

export function updateCache() {
	caches.open(PRECACHE_NAME).then(function (cache) {
		cache.keys().then(function (requests) {
			Promise.all(
				requests.map(function (request) {
					fetch(request, {
						method: 'HEAD',
						headers: {
							'Cache-Control': 'max-age=0 no-cache',
							'Service-Worker': 'false',
						},
					})
						.then(function (response) {
							if (!response.ok) {
								// If the response is not ok, delete the cache item
								cache.delete(request);
							} else {
								cache.put(request, response);
							}
						})
						.catch(function () {
							// If fetch fails, the device might be offline. In this case, don't delete the item.
							// Resolve the promise with a default value to allow Promise.all to continue
							return Promise.resolve('Fetch failed');
						});
				})
			);
		});
	});
}

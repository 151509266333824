export const fallbackTranslations: { [key: string]: string } = {
	WarningStartingCheckDefaultWarning:
		'There is a connection error. Please check your WIFI connection and your device time settings.',
	WarningStartingCheckOfflineRedirectPossible:
		'You are offline but will be redirected shortly.',
	WarningStartingCheckOffline:
		'You are offline. Please check your WIFI connection.',
	WarningStartingCheckTimeOff:
		'The difference between device time and the time server is too large. Please fix the date and time in the settings.',
};

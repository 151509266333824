import React from 'react';
import './styles.scss';
import { Placeholder } from '../../assets/Icons';

export default function Label() {
	const orientation = new URLSearchParams(window.location.search).get(
		'orientation'
	);

	return (
		<div className='label'>
			{orientation && (
				<div className={`orientation-indicator ${orientation}`}>
					{Placeholder}
				</div>
			)}
		</div>
	);
}

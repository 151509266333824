import Progress from '../Progress';
import { ScreenEnum } from '../../enums';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLottie } from 'lottie-react';
import loadingAnimation from '../../assets/Animations/Loading-circle-300x300_round-cap.json';

export default function Waiting(props: {
	screen: ScreenEnum;
	setScreen: (next: ScreenEnum) => void;
	headline?: string;
	text?: string;
}) {
	const { t } = useTranslation();

	const { View } = useLottie({
		animationData: loadingAnimation,
		loop: true,
		className: 'animation',
	});

	let name = ScreenEnum[props.screen];
	name = name.charAt(0).toUpperCase() + name.slice(1);

	return (
		<>
			<h1>{props.headline || t(name + 'Headline')}</h1>
			<p className='p1'>{props.text || t(name + 'Text')}</p>
			<Progress {...props} />
			<>{View}</>
		</>
	);
}

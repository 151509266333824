import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { initReactI18next } from 'react-i18next';
import AppStateProvider from './AppStateProvider';
import { ErrorBoundary } from './error-boundary';
import { registerSW } from './utils/registerSW';
import * as i18next from 'i18next';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

i18next.use(initReactI18next).init({
	resources: {},
	lng: 'en-US',
	fallbackLng: 'en-US',
	interpolation: {
		escapeValue: false,
	},
});

if ('serviceWorker' in navigator) {
	registerSW();
}

root.render(
	<ErrorBoundary>
		<React.StrictMode>
			<AppStateProvider>
				<App />
			</AppStateProvider>
		</React.StrictMode>
	</ErrorBoundary>
);

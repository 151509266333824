import { EyeHide, EyeShow } from '../../assets/Icons';
import React, { useState } from 'react';
import Input from './index';
import { InputProps } from './interfaces';

export default function PasswordInput(props: InputProps) {
	const [showPW, setShowPW] = useState<boolean>(false);

	return (
		<Input
			{...props}
			type={showPW ? 'text' : 'password'}
			icon={
				<div className='show' onClick={() => setShowPW(!showPW)}>
					{showPW ? EyeHide : EyeShow}
				</div>
			}
		/>
	);
}

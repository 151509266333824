import React, { useContext } from 'react';
import './styles.scss';
import { SelectBrandProps } from './interfaces';
import { ScreenEnum } from '../../enums';
import Progress from '../../components/Progress';
import { useTranslation } from 'react-i18next';
import AppStateContext from '../../AppStateProvider/context';

export default function SelectBrand(props: SelectBrandProps) {
	const { state, dispatch } = useContext(AppStateContext);
	const { t } = useTranslation();
	return (
		<>
			<div className='top'>
				<div className='select-brand'>
					{props.backBtn(
						state.apk ? ScreenEnum.setSecond : ScreenEnum.welcome
					)}
					<h1>{t('SelectBrandHeadline')}</h1>
					<p className='p1'>{t('SelectBrandText')}</p>
					<Progress
						screen={ScreenEnum.selectBrand}
						setScreen={props.setScreen}
					/>
					<div className='brands'>
						{state.dataJson.brands.map(
							(brand: any, index: number) => (
								<button
									className={`brand${
										state.brand === brand.name
											? ' active'
											: ''
									}`}
									disabled={brand.disabled}
									key={`brand-${index}`}
									onClick={() => {
										dispatch({
											type: 'SET_BRAND',
											value: brand.name,
										});
										dispatch({
											type: 'SET_MARKET',
											value: brand.markets
												? brand.markets.find(
														(m: any) =>
															m.name === 'DEU'
												  ) || brand.markets[0]
												: null,
										});
									}}
									dangerouslySetInnerHTML={{
										__html: brand.logo,
									}}
								/>
							)
						)}
					</div>
				</div>
			</div>
			<div className='buttons'>
				<button
					className='white'
					disabled={state.brand === null}
					onClick={() => props.setScreen(ScreenEnum.selectMarket)}
				>
					{t('ButtonConfirm')}
				</button>
				{props.cancelBtn}
			</div>
		</>
	);
}

import { decodeString } from './encoding';

export function getSetup() {
	let storage = localStorage.getItem('setup');

	if (!storage) return null;

	let json = JSON.parse(storage);
	json.password = decodeString(json.password) || null;
	json.userPassword = decodeString(json.userPassword) || null;

	return json;
}

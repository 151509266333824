import React, { useContext, useState } from 'react';
import './styles.scss';
import Dropdown from '../../components/Dropdown';
import { SelectMarketProps } from './interfaces';
import { ScreenEnum } from '../../enums';
import Progress from '../../components/Progress';
import { useTranslation } from 'react-i18next';
import AppStateContext from '../../AppStateProvider/context';
import Input from '../../components/Input';
import PasswordInput from '../../components/Input/passwordInput';
import WarningOverlay from '../../components/WarningOverlay';
import { isValidUrl } from '../../utils/isValidUrl';
import { getUrl } from '../../utils/getUrl';

export default function SelectMarket(props: SelectMarketProps) {
	const { t } = useTranslation();
	const { state, dispatch } = useContext(AppStateContext);
	const [clicks, setClicks] = useState<number>(0);
	const [lastClick, setLastClick] = useState<number | null>(null);
	const [expertMode, setExpertMode] = useState<boolean>(false);
	const [showErrorOverlay, setShowErrorOverlay] = useState<boolean>(true);

	if (state.brand === null) {
		return <h1>{t('SelectMarketNoBrand')}</h1>;
	}

	const brand = state.dataJson.brands?.find(
		(b: any) => b.name === state.brand
	);

	const customMarktOptions = (option: any) => ({
		...option,
		label: !option.label.includes(' ') ? (
			option.label
		) : (
			<>
				{option.label.slice(0, option.label.indexOf(' ') + 1)}
				<span className='small'>
					<br />
					{option.label.slice(option.label.indexOf(' ') + 1)}
				</span>
			</>
		),
	});

	function expertModeToggle() {
		if (lastClick === null || Date.now() - lastClick > 1000) {
			setLastClick(Date.now());
			setClicks(1);
		} else {
			if (clicks === 12) {
				setExpertMode(!expertMode);
			}
			setLastClick(Date.now());
			setClicks(prev => prev + 1);
		}
	}

	return (
		<>
			<div className='top'>
				<div className='select-market'>
					{props.backBtn(ScreenEnum.selectBrand)}
					<h1 onClick={expertModeToggle}>
						{t('SelectMarketHeadline')}
					</h1>
					{state.errors.validationError ? (
						<p className='p1 error-message'>
							{t('SelectMarketErrorMessage')}
						</p>
					) : (
						<p className='p1'>{t('SelectMarketText')}</p>
					)}
					<Progress
						screen={ScreenEnum.selectMarket}
						setScreen={props.setScreen}
					/>
					<form autoComplete='off'>
						{!expertMode && (
							<label htmlFor='market'>
								<h4>{t('SelectMarketLabelMarket')}</h4>
								<Dropdown
									list={brand.markets || []}
									set={(market: any) =>
										dispatch({
											type: 'SET_MARKET',
											value: (brand.markets || []).find(
												(m: any) => m.name === market
											),
										})
									}
									defaultValue={state.market?.name}
									customOptions={customMarktOptions}
								/>
							</label>
						)}
						{!expertMode && (
							<Input
								labelHeadline={t('SelectMarketLabelKVPS')}
								errorMessage={
									(state.kvps !== null &&
									state.kvps !== '' &&
									!/^\d+$/.test(state.kvps.toString())
										? t('SelectMarketErrorKVPS')
										: undefined) ||
									state.errors.urlError ||
									(state.url && !isValidUrl(state.url))
										? t(
												'SelectMarketErrorKVPSMarketCombination'
										  )
										: undefined
								}
								id='kvps'
								value={state.kvps ? state.kvps : ''}
								placeholder={t('SelectMarketPlaceholderKVPS')}
								onInput={e =>
									dispatch({
										type: 'SET_KVPS',
										value: (e.target as HTMLInputElement)
											.value,
									})
								}
							/>
						)}
						{expertMode && (
							<Input
								labelId='label-url'
								labelHeadline={t('SelectMarketLabelURL')}
								errorMessage={
									state.errors.urlError ||
									(state.url && !isValidUrl(state.url))
										? t('SelectMarketErrorURL')
										: undefined
								}
								type='url'
								id='url'
								value={state.url ? state.url : ''}
								placeholder={t('SelectMarketPlaceholderURL')}
								onInput={e =>
									dispatch({
										type: 'SET_URL',
										value: (e.target as HTMLInputElement)
											.value,
									})
								}
								required={true}
								inputMode='url'
							/>
						)}
						<PasswordInput
							labelId='label-pw'
							labelHeadline={t('SelectMarketLabelPassword')}
							errorMessage={
								state.errors.passwordError
									? t('SelectMarketErrorPassword')
									: undefined
							}
							value={state.password ? state.password : ''}
							id='pw'
							className='pw'
							placeholder={t('SelectMarketPlaceholderPassword')}
							onInput={e =>
								dispatch({
									type: 'SET_PASSWORD',
									value: (e.target as HTMLInputElement).value,
								})
							}
						/>
					</form>
					{showErrorOverlay &&
						state.errors.validationError +
							state.errors.urlError +
							state.errors.passwordError >
							5 && (
							<WarningOverlay
								headline={'ValidationFailedHeadline'}
								texts={['ValidationFailedText']}
								btnFunction={() => setShowErrorOverlay(false)}
							/>
						)}
				</div>
			</div>
			{
				<div className='buttons'>
					<button
						className='white'
						disabled={
							(state.url === null ||
								!isValidUrl(state.url) ||
								(!expertMode && state.kvps == '') ||
								(!expertMode && state.kvps == null) ||
								(!expertMode &&
									state.kvps !== null &&
									state.kvps !== '' &&
									!/^\d+$/.test(state.kvps))) &&
							(state.market === null ||
								state.kvps === null ||
								state.kvps === '' ||
								(!expertMode && !/^\d+$/.test(state.kvps)) ||
								state.password === null ||
								state.password.length === 0)
						}
						onClick={() => {
							if (
								!expertMode &&
								state.market &&
								state.kvps &&
								state.brand
							) {
								dispatch({
									type: 'SET_URL',
									value: getUrl(
										state.market,
										state.kvps,
										state.brand,
										null
									),
								});
							}
							if (expertMode) {
								dispatch({
									type: 'SET_KVPS',
									value: null,
								});
							}
							props.setScreen(ScreenEnum.validation);
						}}
					>
						{t('ButtonConfirm')}
					</button>
					{props.cancelBtn}
				</div>
			}
		</>
	);
}

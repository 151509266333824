import { AppState } from './interfaces';

export type Action =
	| { type: 'SET_BRAND'; value: AppState['brand'] }
	| { type: 'SET_MARKET'; value: AppState['market'] }
	| { type: 'SET_KVPS'; value: AppState['kvps'] }
	| { type: 'SET_PASSWORD'; value: AppState['password'] }
	| { type: 'SET_DEVICES'; value: AppState['devices'] }
	| { type: 'SET_DEVICE'; value: AppState['device'] }
	| { type: 'SET_URL'; value: AppState['url'] }
	| { type: 'SET_LANGUAGE'; value: AppState['language'] }
	| { type: 'SET_USER_PASSWORD'; value: AppState['userPassword'] }
	| { type: 'SET_SCREEN'; value: AppState['screen'] }
	| { type: 'SET_ERRORS'; value: AppState['errors'] }
	| { type: 'SET_GO_TO_LABEL'; value: AppState['goToLabel'] }
	| { type: 'SET_SETUP'; value: AppState['setup'] }
	| { type: 'SET_OFFLINE'; value: AppState['offline'] };

export const reducer = (state: AppState, action: Action) => {
	switch (action.type) {
		case 'SET_BRAND':
			return { ...state, brand: action.value };
		case 'SET_MARKET':
			return { ...state, market: action.value };
		case 'SET_KVPS':
			return { ...state, kvps: action.value };
		case 'SET_PASSWORD':
			return { ...state, password: action.value };
		case 'SET_DEVICES':
			return { ...state, devices: action.value };
		case 'SET_DEVICE':
			return { ...state, device: action.value };
		case 'SET_URL':
			return { ...state, url: action.value };
		case 'SET_LANGUAGE':
			return { ...state, language: action.value };
		case 'SET_USER_PASSWORD':
			return { ...state, userPassword: action.value };
		case 'SET_SCREEN':
			return { ...state, screen: action.value };
		case 'SET_ERRORS':
			return { ...state, errors: action.value };
		case 'SET_GO_TO_LABEL':
			return { ...state, goToLabel: action.value };
		case 'SET_SETUP':
			return { ...state, setup: action.value };
		case 'SET_OFFLINE':
			return { ...state, offline: action.value };
		default:
			return state;
	}
};

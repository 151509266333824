import React from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { deviceTypeList } from '../../utils/deviceTypeList';

export default function DeviceTypeSelection(props: {
	deviceType: string | null;
	setDeviceType: React.Dispatch<React.SetStateAction<string | null>>;
}) {
	const { t } = useTranslation();

	return (
		<SimpleBar
			className='device-type-selection'
			forceVisible='y'
			autoHide={false}
		>
			<div>
				{deviceTypeList.map((dT, index) => (
					<div
						className={`device-type-option${
							dT.img === props.deviceType ? ' selected' : ''
						}`}
						onClick={() => {
							if (dT.img !== props.deviceType) {
								props.setDeviceType(dT.img);
							} else props.setDeviceType(null);
						}}
						key={`device-type-${dT}-${index}`}
					>
						<img
							alt={dT.name}
							src={'./device-types/' + dT.img + '.png'}
						/>
						<h4>{t('DeviceType' + dT.name)}</h4>
					</div>
				))}
			</div>
		</SimpleBar>
	);
}

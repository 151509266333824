export const PRECACHE_NAME = 'did-web-precache';

export async function registerSW() {
	const notAlreadyRegistered = await navigator.serviceWorker
		.getRegistrations()
		.then(registrations => registrations.length === 0);

	if (notAlreadyRegistered)
		navigator.serviceWorker
			.register('./serviceWorker.js')
			.then(res => {
				console.log('service worker registered');
				const data = {
					type: 'CACHE_URLS',
					cacheEnding: '',
					payload: Array.from(
						new Set(
							[
								...performance
									.getEntriesByType('resource')
									.map(r => r.name)
									.filter(name =>
										name.includes(window.location.origin)
									),
								'/',
								'/index.html',
								'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap',
							].map(url =>
								url.replace(window.location.origin, '')
							)
						)
					),
				};
				const sw = res.installing || res.active;
				if (sw) {
					sw.postMessage(data);
				}
			})
			.catch(err => console.log('service worker not registered', err));

	navigator.serviceWorker.addEventListener('message', event => {
		console.log('sw-cache-ready', event.data);
		localStorage.setItem(
			'sw-cache-ready' + (event.data.brand ? `-${event.data.brand}` : ''),
			event.data.msg
		);
	});
}

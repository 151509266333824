export enum ScreenEnum {
	launch,
	welcome,
	setSecond,
	hardwareCheck,
	softwareCheck,
	selectBrand,
	selectMarket,
	validation,
	selectDevice,
	validationDevice,
	password,
	final,
	exit,
	exitNoCancel,
}

export function getLanguage(
	dataLangs: Array<{ value: string; label: string }>,
	setup: any,
	langParam: string | null
): string {
	const found = dataLangs.find((l: any) => l.value === langParam);
	if (found) return found.value;
	if (setup && setup.language) return setup.language;
	let foundBrowser = dataLangs.find(
		(l: any) => l.value === navigator.language // browser language
	);
	if (!foundBrowser)
		foundBrowser = dataLangs.find(
			(l: any) =>
				l.value.split('-')[0] === navigator.language.split('-')[0] // en -> en-US
		);
	if (foundBrowser) return foundBrowser.value;
	return dataLangs[0].value;
}

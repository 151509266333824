import React, { useRef, useState } from 'react';
import { default as SimpleKeyboard } from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import './styles.scss';

export default function Keyboard(props: {
	onEnter?: Function;
	noMovement?: true;
}): JSX.Element {
	let ref = useRef<any>(null);
	const [layoutName, setLayoutName] = useState('default');
	const [input, setInput] = useState<HTMLInputElement | null>(null);

	const onKeyPress = (button: string) => {
		if (button === '{shift}') {
			setLayoutName(layoutName === 'default' ? 'shift' : 'default');
		}
		if (button === '{enter}') {
			if (props.onEnter) props.onEnter();
			if (input) {
				input.blur();
				setInput(null);
			}
		}
	};

	window.addEventListener('keyboard-focus', (e: any) => {
		if (!input && e.detail) {
			const input = e.detail.input as HTMLInputElement;
			ref.current?.setInput(input.value);
			setInput(input);
		}
	});

	window.addEventListener('keyboard-blur', (e: any) => {
		setInput(null);
	});

	window.addEventListener('keypress', function (e) {
		if (e.key === 'Enter') {
			onKeyPress('{enter}');
		}
	});

	return (
		<div
			className={`keyboard-wrapper${
				!props.noMovement ? (input ? ' show' : ' hide') : ' no-movement'
			}`}
			onClick={e => e.stopPropagation()}
		>
			<SimpleKeyboard
				keyboardRef={ref ? r => (ref.current = r) : undefined}
				layoutName={layoutName}
				onChange={(i: string) => {
					if (input) {
						input.value = i;
						input.dispatchEvent(
							new Event('input', { bubbles: true })
						);
					}
				}}
				onKeyPress={onKeyPress}
				display={{
					'{bksp}': '\u21E6',
					'{enter}': 'Enter',
					'{shift}': '\u21E7',
					'{space}': ' ',
				}}
			/>
		</div>
	);
}

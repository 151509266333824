import React from 'react';
import './styles.scss';
import Select from 'react-select';
import { DropdownProps } from './interfaces';

export default function Dropdown(props: DropdownProps) {
	let options: { label: string; value: string }[] = [];
	props.list.forEach((element: any) =>
		options.push({
			label: element.label || element.name || element,
			value: element.value || element.name || element,
		})
	);
	if (props.customOptions) {
		options = options.map(props.customOptions);
	}
	const oneRem = window.innerHeight / 1920;

	return (
		<Select
			options={options}
			className='select'
			classNamePrefix='select'
			maxMenuHeight={5 * 60 * oneRem} // max 5 normal-sized options visible
			openMenuOnClick={true}
			closeMenuOnSelect={true}
			isSearchable={false}
			onChange={(option: any) => props.set(option.value)}
			placeholder={
				props.placeholder !== undefined
					? props.placeholder
					: props.defaultValue
					? false
					: options[0].label
			}
			defaultValue={
				props.defaultValue !== undefined
					? options.find(
							(option: any) => option.label === props.defaultValue
					  ) || false
					: false
			}
		/>
	);
}

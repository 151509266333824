import React from 'react';
import Unlock from '../../components/Unlock';
import { useTranslation } from 'react-i18next';
import { ScreenEnum } from '../../enums';

export default function Exit(props: {
	setScreen?: (next: ScreenEnum) => void;
}) {
	const { t } = useTranslation();

	return (
		<>
			<div className='top'>
				<div className='exit'>
					<h1>
						{t('ExitHeadline') === 'ExitHeadline'
							? 'Exit'
							: t('ExitHeadline')}
					</h1>
					<p className='p1'>
						{t('ExitText') === 'ExitText'
							? 'Please enter the password to break out of the application and fix the settings.'
							: t('ExitText')}
					</p>
					<Unlock
						inFrame={false}
						close={true}
						cancel={
							props.setScreen === undefined
								? undefined
								: () =>
										props.setScreen &&
										props.setScreen(ScreenEnum.welcome)
						}
					/>
				</div>
			</div>
		</>
	);
}

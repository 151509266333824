import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLottie } from 'lottie-react';
import checkAnimation from '../../assets/Animations/Success-300x300.json';
import { getUrl } from '../../utils/getUrl';
import { redirect } from '../../utils/redirect';
import AppStateContext from '../../AppStateProvider/context';
import { complete } from '../../utils/complete';
import addParam from '../../utils/addParam';
import { encodeString } from '../../utils/encoding';

export default function Final() {
	const { t, i18n } = useTranslation();
	const { state } = useContext(AppStateContext);
	const completeState = complete(state);

	const { View } = useLottie({
		animationData: checkAnimation,
		loop: false,
		className: 'animation',
		onComplete: () => {
			if (completeState) {
				redirect(state.brand);
			}
		},
	});

	if (!completeState) {
		return (
			<div className='top'>
				<div className='final'>
					<h1>{t('FinalErrorHeadline')}</h1>
					<p className='p1'>{t('FinalErrorText')}</p>
				</div>
			</div>
		);
	}

	const setupString = JSON.stringify({
		brand: state.brand,
		market: {
			...state.market,
			calcDomain:
				state.market?.calcDomain ||
				(state.dataJson.brands as Array<any>).find(
					b => b.name === state.brand
				)?.calcDomain,
		},
		kvps: state.kvps && /^\d+$/.test(state.kvps) ? state.kvps : null,
		password: encodeString(state.password),
		userPassword: encodeString(state.userPassword),
		device: state.device,
		url: getUrl(state.market, state.kvps, state.brand, state.url),
		language: i18n.language,
		label: state.goToLabel,
	});

	localStorage.setItem('setup', setupString);

	if (state.apk) {
		// make account.ini
		addParam(
			'account',
			[
				'url=' +
					getUrl(state.market, state.kvps, state.brand, state.url) +
					'/module/content/bpca/depot',
				'user=ssp',
				'password=' + state.password,
				'id=' + state.device,
				'name=' +
					(
						state.devices?.find(d =>
							d.includes(`(${state.device})`)
						) || ''
					).split(' ')[0],
			].join('\n'),
			true
		);
	}

	return (
		<div className='top'>
			<div className='final'>
				<h1>{t('FinalHeadline')}</h1>
				<p className='p1'>{t('FinalText')}</p>
				<>{View}</>
			</div>
		</div>
	);
}
